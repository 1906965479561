import React, { Component } from "react";
import * as d3 from "d3";
import $ from "jquery";

class Piechart extends Component {
  shouldComponentUpdate(nextProps) {
    this.pieChart(nextProps.pieChart, nextProps.year);
    return true;
  }

  componentDidMount() {
    this.pieChart(this.props.pieChart, this.props.year);
    console.log(this.props.pieChart);
  }
  componentWillUnmount() {}
  pieChart = (data, year) => {
    const self = this;
    var width = 350,
      height = 350;
    $("#pieChart").empty();
    var r = Math.min(width / 2, height / 2);
    var color = d3
      .scaleOrdinal()
      .range(["#FF7DAA", "#7DAAFF", " #E77DFF", "#95FF7D"]);
    var vis = d3
      .select("#pieChart")
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", "translate(" + width / 2 + ", " + height / 2 + ")");

    var pie = d3.pie().value(function (d) {
      return d.revenue;
    });

    var arcs = d3
      .arc()
      .outerRadius(r - 10)
      .innerRadius(r - 70);

    var farc = d3
      .arc()
      .outerRadius(r)
      .innerRadius(r - 70);

    const render = (data, year) => {
      var g = vis
        .selectAll(".arc")
        .data(pie(data[year].quarterly))
        .enter()
        .append("g")
        .attr("class", "arc")
        .on("mouseover", mouseover)
        .on("mouseout", mouseout);

      g.append("path")
        .attr("d", arcs)
        .on("click", function (d) {
          self.props.generateBarChart(d);
        })
        .attr("fill", function (d) {
          return color(d.data.quarter);
        })
        .attr("opacity", "0.6")
        .attr("stroke", "white")
        .attr("stroke-width", "5px")
        .transition()
        .ease(d3.easeLinear);

      function mouseover() {
        d3.select(this)
          .select("path")
          .transition()
          .duration(750)
          //.attr("stroke","red")
          //.attr("stroke-width", 1.5)
          .attr("d", farc);
        console.log("sssss");
      }

      function mouseout() {
        d3.select(this)
          .select("path")
          .transition()
          .duration(750)
          //.attr("stroke","blue")
          //.attr("stroke-width", 1.5)
          .attr("d", arcs);
      }

      g.filter(function (d) {
        return d.endAngle - d.startAngle > 0.2;
      })
        .append("svg:text")
        .attr("dy", ".35em")
        .attr("fill", "black")
        .attr("text-anchor", "middle")
        .attr("transform", function (d) {
          return (
            "translate(" + arcs.centroid(d) + ")rotate(" + self.angle(d) + ")"
          );
        })
        .text(function (d) {
          return "Q" + d.data.quarter;
        });
    };
    render(data, year);
  };
  angle = (d) => {
    var a = ((d.startAngle + d.endAngle) * 90) / Math.PI - 90;
    return a > 90 ? a - 180 : a;
  };
  generateBarChart = (d) => {};
  render() {
    return <div id="pieChart"></div>;
  }
}

export default Piechart;
