import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { cashflowTimeSeriesData } from "../../../../redux/actions/cashFlowTimeSeriesActions";
import isEmpty from "../../../../validation/is-empty";
import RadialChart from "./RadialChart";
import RequestLoadingData from "../../../Loading/RequestLoadingData";
import PieChart from "./PieChart";
import BarChart from "./BarChart";
import * as d3 from "d3";
import KPICashflowTimeSeries from "./KPICashflowTimeSeries";

class CashflowTimeSeries extends Component {
  state = {
    timeSeries: null,
    gotResult: false,
    cumulativeData: null,
    years: null,
    selectYear: null,
    initialData: null,
    type: "weekly",
    barData: null,
    savedQuarter: null,
    hideBarChart: false,
    selectingQuarter: "",
  };

  componentDidMount() {
    this.props.cashflowTimeSeriesData();
  }

  componentWillReceiveProps(nextProps) {
    const { cashflowTimeSeriesDetails } = nextProps.cashFlowTimeSeries;
    if (cashflowTimeSeriesDetails !== null) {
      // const timeSeries = cashflowTimeSeriesDetails;
      // console.log("time", timeSeries);
      // console.log(timeSeries);

      const dataFormat = {
        "2018-19": {
          weekly: [
            { week: 1, weekly_revenue: NaN },
            { week: 2, weekly_revenue: NaN },
            { week: 3, weekly_revenue: NaN },
            { week: 4, weekly_revenue: NaN },
            { week: 5, weekly_revenue: NaN },
            { week: 6, weekly_revenue: NaN },
            { week: 7, weekly_revenue: NaN },
            { week: 8, weekly_revenue: NaN },
            { week: 9, weekly_revenue: NaN },
            { week: 10, weekly_revenue: NaN },
            { week: 11, weekly_revenue: NaN },
            { week: 12, weekly_revenue: NaN },
            { week: 13, weekly_revenue: NaN },
            { week: 14, weekly_revenue: NaN },
            { week: 15, weekly_revenue: NaN },
            { week: 16, weekly_revenue: NaN },
            { week: 17, weekly_revenue: NaN },
            { week: 18, weekly_revenue: NaN },
            { week: 19, weekly_revenue: NaN },
            { week: 20, weekly_revenue: NaN },
            { week: 21, weekly_revenue: NaN },
            { week: 22, weekly_revenue: NaN },
            { week: 23, weekly_revenue: NaN },
            { week: 24, weekly_revenue: NaN },
            { week: 25, weekly_revenue: NaN },
            { week: 26, weekly_revenue: NaN },
            { week: 27, weekly_revenue: NaN },
            { week: 28, weekly_revenue: NaN },
            { week: 29, weekly_revenue: NaN },
            { week: 30, weekly_revenue: NaN },
            { week: 31, weekly_revenue: NaN },
            { week: 32, weekly_revenue: NaN },
            { week: 33, weekly_revenue: NaN },
            { week: 34, weekly_revenue: NaN },
            { week: 35, weekly_revenue: NaN },
            { week: 36, weekly_revenue: NaN },
            { week: 37, weekly_revenue: NaN },
            { week: 38, weekly_revenue: NaN },
            { week: 39, weekly_revenue: NaN },
            { week: 40, weekly_revenue: NaN },
            { week: 41, weekly_revenue: NaN },
            { week: 42, weekly_revenue: NaN },
            { week: 43, weekly_revenue: NaN },
            { week: 44, weekly_revenue: NaN },
            { week: 45, weekly_revenue: NaN },
            { week: 46, weekly_revenue: NaN },
            { week: 47, weekly_revenue: NaN },
            { week: 48, weekly_revenue: NaN },
            { week: 49, weekly_revenue: NaN },
            { week: 50, weekly_revenue: NaN },
            { week: 51, weekly_revenue: NaN },
            { week: 52, weekly_revenue: NaN },
          ],
          monthly: [],
          quarterly: [
            { quarter: 1, revenue: NaN },
            { quarter: 2, revenue: NaN },
            { quarter: 3, revenue: NaN },
            { quarter: 4, revenue: NaN },
            // More quarterly data...
          ],
        },
        "2017-18": {
          weekly: [
            { week: 1, weekly_revenue: NaN },
            { week: 2, weekly_revenue: NaN },
            { week: 3, weekly_revenue: NaN },
            { week: 4, weekly_revenue: NaN },
            { week: 5, weekly_revenue: NaN },
            { week: 6, weekly_revenue: NaN },
            { week: 7, weekly_revenue: NaN },
            { week: 8, weekly_revenue: NaN },
            { week: 9, weekly_revenue: NaN },
            { week: 10, weekly_revenue: NaN },
            { week: 11, weekly_revenue: NaN },
            { week: 12, weekly_revenue: NaN },
            { week: 13, weekly_revenue: NaN },
            { week: 14, weekly_revenue: NaN },
            { week: 15, weekly_revenue: NaN },
            { week: 16, weekly_revenue: NaN },
            { week: 17, weekly_revenue: NaN },
            { week: 18, weekly_revenue: NaN },
            { week: 19, weekly_revenue: NaN },
            { week: 20, weekly_revenue: NaN },
            { week: 21, weekly_revenue: NaN },
            { week: 22, weekly_revenue: NaN },
            { week: 23, weekly_revenue: NaN },
            { week: 24, weekly_revenue: NaN },
            { week: 25, weekly_revenue: NaN },
            { week: 26, weekly_revenue: NaN },
            { week: 27, weekly_revenue: NaN },
            { week: 28, weekly_revenue: NaN },
            { week: 29, weekly_revenue: NaN },
            { week: 30, weekly_revenue: NaN },
            { week: 31, weekly_revenue: NaN },
            { week: 32, weekly_revenue: NaN },
            { week: 33, weekly_revenue: NaN },
            { week: 34, weekly_revenue: NaN },
            { week: 35, weekly_revenue: NaN },
            { week: 36, weekly_revenue: NaN },
            { week: 37, weekly_revenue: NaN },
            { week: 38, weekly_revenue: NaN },
            { week: 39, weekly_revenue: NaN },
            { week: 40, weekly_revenue: NaN },
            { week: 41, weekly_revenue: NaN },
            { week: 42, weekly_revenue: NaN },
            { week: 43, weekly_revenue: NaN },
            { week: 44, weekly_revenue: NaN },
            { week: 45, weekly_revenue: NaN },
            { week: 46, weekly_revenue: NaN },
            { week: 47, weekly_revenue: NaN },
            { week: 48, weekly_revenue: NaN },
            { week: 49, weekly_revenue: NaN },
            { week: 50, weekly_revenue: NaN },
            { week: 51, weekly_revenue: NaN },
            { week: 52, weekly_revenue: NaN },
          ],
          monthly: [],
          quarterly: [
            { quarter: 1, revenue: NaN },
            { quarter: 2, revenue: NaN },
            { quarter: 1, revenue: NaN },
            { quarter: 2, revenue: NaN },
            { quarter: 3, revenue: NaN },
            { quarter: 4, revenue: NaN },
          ],
        },
      };
      const timeSeries = {
        "2018-19": {
          weekly: [
            { week: 1, weekly_revenue: NaN },
            { week: 2, weekly_revenue: NaN },
            { week: 3, weekly_revenue: NaN },
            { week: 4, weekly_revenue: NaN },
            { week: 5, weekly_revenue: NaN },
            { week: 6, weekly_revenue: NaN },
            { week: 7, weekly_revenue: NaN },
            { week: 8, weekly_revenue: NaN },
            { week: 9, weekly_revenue: NaN },
            { week: 10, weekly_revenue: NaN },
            { week: 11, weekly_revenue: NaN },
            { week: 12, weekly_revenue: NaN },
            { week: 13, weekly_revenue: NaN },
            { week: 14, weekly_revenue: NaN },
            { week: 15, weekly_revenue: NaN },
            { week: 16, weekly_revenue: NaN },
            { week: 17, weekly_revenue: NaN },
            { week: 18, weekly_revenue: NaN },
            { week: 19, weekly_revenue: NaN },
            { week: 20, weekly_revenue: NaN },
            { week: 21, weekly_revenue: NaN },
            { week: 22, weekly_revenue: NaN },
            { week: 23, weekly_revenue: NaN },
            { week: 24, weekly_revenue: NaN },
            { week: 25, weekly_revenue: NaN },
            { week: 26, weekly_revenue: NaN },
            { week: 27, weekly_revenue: NaN },
            { week: 28, weekly_revenue: NaN },
            { week: 29, weekly_revenue: NaN },
            { week: 30, weekly_revenue: NaN },
            { week: 31, weekly_revenue: NaN },
            { week: 32, weekly_revenue: NaN },
            { week: 33, weekly_revenue: NaN },
            { week: 34, weekly_revenue: NaN },
            { week: 35, weekly_revenue: NaN },
            { week: 36, weekly_revenue: NaN },
            { week: 37, weekly_revenue: NaN },
            { week: 38, weekly_revenue: NaN },
            { week: 39, weekly_revenue: NaN },
            { week: 40, weekly_revenue: NaN },
            { week: 41, weekly_revenue: NaN },
            { week: 42, weekly_revenue: NaN },
            { week: 43, weekly_revenue: NaN },
            { week: 44, weekly_revenue: NaN },
            { week: 45, weekly_revenue: NaN },
            { week: 46, weekly_revenue: NaN },
            { week: 47, weekly_revenue: NaN },
            { week: 48, weekly_revenue: NaN },
            { week: 49, weekly_revenue: NaN },
            { week: 50, weekly_revenue: NaN },
            { week: 51, weekly_revenue: NaN },
            { week: 52, weekly_revenue: NaN },
          ],
          monthly: [],
          quarterly: [
            { quarter: 1, revenue: NaN },
            { quarter: 2, revenue: NaN },
            { quarter: 3, revenue: NaN },
            { quarter: 4, revenue: NaN },
            // More quarterly data...
          ],
        },
        "2017-18": {
          weekly: [
            { week: 1, weekly_revenue: NaN },
            { week: 2, weekly_revenue: NaN },
            { week: 3, weekly_revenue: NaN },
            { week: 4, weekly_revenue: NaN },
            { week: 5, weekly_revenue: NaN },
            { week: 6, weekly_revenue: NaN },
            { week: 7, weekly_revenue: NaN },
            { week: 8, weekly_revenue: NaN },
            { week: 9, weekly_revenue: NaN },
            { week: 10, weekly_revenue: NaN },
            { week: 11, weekly_revenue: NaN },
            { week: 12, weekly_revenue: NaN },
            { week: 13, weekly_revenue: NaN },
            { week: 14, weekly_revenue: NaN },
            { week: 15, weekly_revenue: NaN },
            { week: 16, weekly_revenue: NaN },
            { week: 17, weekly_revenue: NaN },
            { week: 18, weekly_revenue: NaN },
            { week: 19, weekly_revenue: NaN },
            { week: 20, weekly_revenue: NaN },
            { week: 21, weekly_revenue: NaN },
            { week: 22, weekly_revenue: NaN },
            { week: 23, weekly_revenue: NaN },
            { week: 24, weekly_revenue: NaN },
            { week: 25, weekly_revenue: NaN },
            { week: 26, weekly_revenue: NaN },
            { week: 27, weekly_revenue: NaN },
            { week: 28, weekly_revenue: NaN },
            { week: 29, weekly_revenue: NaN },
            { week: 30, weekly_revenue: NaN },
            { week: 31, weekly_revenue: NaN },
            { week: 32, weekly_revenue: NaN },
            { week: 33, weekly_revenue: NaN },
            { week: 34, weekly_revenue: NaN },
            { week: 35, weekly_revenue: NaN },
            { week: 36, weekly_revenue: NaN },
            { week: 37, weekly_revenue: NaN },
            { week: 38, weekly_revenue: NaN },
            { week: 39, weekly_revenue: NaN },
            { week: 40, weekly_revenue: NaN },
            { week: 41, weekly_revenue: NaN },
            { week: 42, weekly_revenue: NaN },
            { week: 43, weekly_revenue: NaN },
            { week: 44, weekly_revenue: NaN },
            { week: 45, weekly_revenue: NaN },
            { week: 46, weekly_revenue: NaN },
            { week: 47, weekly_revenue: NaN },
            { week: 48, weekly_revenue: NaN },
            { week: 49, weekly_revenue: NaN },
            { week: 50, weekly_revenue: NaN },
            { week: 51, weekly_revenue: NaN },
            { week: 52, weekly_revenue: NaN },
          ],
          monthly: [],
          quarterly: [
            { quarter: 1, revenue: NaN },
            { quarter: 2, revenue: NaN },
            { quarter: 1, revenue: NaN },
            { quarter: 2, revenue: NaN },
            { quarter: 3, revenue: NaN },
            { quarter: 4, revenue: NaN },
          ],
        },
      };
      let initialData = {};
      // console.log(
      //   "cashflowTimeSeriesDetails[years[0]] Vishwas",
      //   cashflowTimeSeriesDetails
      // );
      // for (let item in cashflowTimeSeriesDetails) {
      //   console.log("item in forIn", cashflowTimeSeriesDetails[item]);
      // }

      const years = Object.keys(timeSeries);
      initialData[years[0]] = dataFormat[years[0]];

      console.log(initialData);
      const selectedYear = years[0];
      // console.log(timeSeries)
      // this.setState( (prevState) => ({
      //     timeSeries: timeSeries,
      //     cumulativeData: timeSeries,
      //     initialData,
      //     years,
      //     selectedYear,
      //     gotResult: true,
      // }))
      this.setState(
        {
          timeSeries: timeSeries,
          cumulativeData: timeSeries,
          initialData,
          years,
          selectedYear,
          gotResult: true,
        },
        () => {
          this.generateBarChart({
            data: {
              quarter: 1,
            },
          });
        }
      );
    }
  }

  onChangeDropdown = (e) => {
    const { value } = e.target;
    const { timeSeries } = this.state;

    const initialData = {};
    initialData[value] = timeSeries[value];
    const selectedYear = value;
    this.setState(
      {
        initialData,
        selectedYear,
        hideBarChart: true,
      },
      () => {
        this.generateBarChart({
          data: {
            quarter: 1,
          },
        });
      }
    );
  };

  generateBarChart = (d) => {
    const { selectedYear, type, initialData } = this.state;
    let { savedQuarter } = this.state;
    let { quarter } = d.data;
    let data = initialData[selectedYear]["weekly"];
    const quarterly = initialData[selectedYear]["quarterly"];
    const offset = 13;
    if (savedQuarter && savedQuarter === quarter) quarter = savedQuarter;
    else {
      savedQuarter = quarter;
    }

    const barData = data
      .slice(offset * (quarter - 1), offset * quarter)
      .filter((item) => item);

    console.log("klesh", barData);

    this.setState({
      type: "weekly",
      barData,
      savedQuarter,
      hideBarChart: false,
      selectingQuarter: quarter,
    });
  };

  onRadioButtonChange = (e) => {
    const { value } = e.target;
    const {
      selectedYear,
      type,
      initialData,
      savedQuarter: quarter,
    } = this.state;
    let offset = 13;
    if (value === "monthly") {
      offset = 3;
    }
    console.log("Initial::::", initialData);
    let data = initialData[selectedYear][value];
    console.log("check", selectedYear, value);
    if (value === "monthly") {
      data = data.map((item) => {
        // var parse = d3.timeParse("%b")
        // item.month = parse(item.month)
        if (typeof item.month !== "object") {
          const format = d3.timeParse("%b-%Y");
          item.month = format(item.month);
        }
        console.log(item);
        return item;
      });
      data = data.sort((a, b) => a.month - b.month);
      data = data.map((d) => {
        // console.log(va)
        d.month = d3.timeFormat("%b-%Y")(d.month);
        console.log(d);
        return d;
      });
    }

    let barData = null;
    barData = data
      .slice(offset * (quarter - 1), offset * quarter)
      .filter((item) => item);

    this.setState({
      type: value,
      barData,
    });
  };

  render() {
    const { loading, cashflowTimeSeriesDetails } =
      this.props.cashFlowTimeSeries;

    let cashflowTimeSeries;
    if (cashflowTimeSeriesDetails !== null && this.state.gotResult) {
      if (isEmpty(cashflowTimeSeriesDetails) === true) {
        cashflowTimeSeries = (
          <div>
            <p className="lead text-muted">Sorry</p>
            <p>Something went wrong please try again after some time</p>
          </div>
        );
      } else {
        const { timeSeries, years } = this.state;

        const getSelectBox = () => {
          return years.map((item, idx) => <option key={idx}> {item} </option>);
        };
        cashflowTimeSeries = (
          <div className="container-fluid">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                <div className="card timeSeries-card">
                  {this.state.timeSeries && (
                    <RadialChart radialChart={this.state.timeSeries} />
                  )}
                </div>
              </div>
              <KPICashflowTimeSeries timeSeries={this.state.timeSeries} />
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card timeSeries-card It-chart">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="timeseries-select">
                        Select the Year
                        {timeSeries && (
                          <select onChange={(e) => this.onChangeDropdown(e)}>
                            {getSelectBox()}
                          </select>
                        )}
                      </div>
                      <div className="text-center">
                        <div className="timeseries-title">
                          Quarterly Sales ({this.state.selectedYear})
                        </div>
                        {this.state.initialData && (
                          <PieChart
                            pieChart={this.state.initialData}
                            year={this.state.selectedYear}
                            generateBarChart={this.generateBarChart}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      {this.state.barData && !this.state.hideBarChart && (
                        <div>
                          <input
                            type="radio"
                            name="month_week_wise"
                            value="monthly"
                            checked={this.state.type === "monthly"}
                            onChange={(e) => this.onRadioButtonChange(e)}
                          />{" "}
                          Monthly
                          <input
                            type="radio"
                            name="month_week_wise"
                            value="weekly"
                            checked={this.state.type === "weekly"}
                            onChange={(e) => this.onRadioButtonChange(e)}
                          />{" "}
                          Weekly
                        </div>
                      )}
                      <div className="text-center">
                        {this.state.type === "weekly" ? (
                          <div className="timeseries-title">
                            Weekwise Sales Q{this.state.selectingQuarter} (
                            {this.state.selectedYear})
                          </div>
                        ) : this.state.type === "monthly" ? (
                          <div className="timeseries-title">
                            Monthly Sales Q{this.state.selectingQuarter} (
                            {this.state.selectedYear})
                          </div>
                        ) : null}
                        {this.state.barData && (
                          <BarChart
                            barChartData={this.state.barData}
                            filter={this.state.type}
                            hide={this.state.hideBarChart}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else {
      cashflowTimeSeries = <RequestLoadingData />;
    }
    return <div>{cashflowTimeSeries}</div>;
  }
}

CashflowTimeSeries.propTypes = {
  cashflowTimeSeriesData: PropTypes.func.isRequired,
  cashFlowTimeSeries: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  cashFlowTimeSeries: state.cashFlowTimeSeries,
});

export default connect(mapStateToProps, { cashflowTimeSeriesData })(
  CashflowTimeSeries
);
